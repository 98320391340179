






























import Vue from 'vue';
export default Vue.extend({
  props: {
    tourId: {
      type: String,
      required: true,
    },
    reactionCount: {
      type: Number,
      required: true,
    },
    enableTransparentBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    likeTour(tourId: string) {
      this.$store.dispatch('tour/increaseTourReactions', tourId);
    },
  },
});
