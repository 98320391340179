















import Vue from 'vue';
export default Vue.extend({
  props: {
    memberCount: {
      type: Number,
      required: true,
    },
  },
});
